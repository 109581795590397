// Fonts
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;700&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';

// Typography
h1 {
    font-size:28px;
}

h2 {
    font-size:24px;
}

h3 {
    font-size:18px;
}

h6 {
    font-size:12px;
}

h1, h2, h3, h4, h5, h6, p {
    margin-bottom:0px;
}

//Globals
html {
    background-color:#333;
}
.logo-wrapper {
    margin-top:1rem;
}
.logo-wrapper img {
    max-width:200px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}
.text-right {
    text-align:right;
}
.form-control {
    border-radius:0px;
}
.btn {
    border-radius:0px;
}
.btn-white {
    border-color:#FFFFFF;
    padding-left: 35px;
    padding-right: 35px;
}

.mobile-only-toggler {
    display:block;
}
.mobile-only-toggler.hide {
    display:none;
}
.mobile-inline-block {
    display:inline-block;
}
.toggle-mobile {
    cursor:pointer;
    width:32px;
    height:32px;
    border:1px solid;
    border-radius:50%;
    display: inline-flex;
    align-items:center;
    justify-content: center;
    background-color:#FFFFFF;
}
.toggle-mobile svg {
    fill:#333333;
}
.mobile-only {
    font-size:10px;
    display:inline-block;
    padding-right:15px;
}

@media(min-width:768px) {
    .mobile-only-toggler {
        display:block !important;
    }
    .toggle-mobile {
        display:none !important;
    }
    .mobile-only {
        display:none !important;
    }
}

//Components
.section-form .col-md-6:first-of-type {
    border-right:2px solid white;
}
.section-nav span.rounded-pill {
    background-color:#FFFFFF;
    color:#333333;
    width:30px;
    height:30px;
}
.section-nav .step {
    opacity:0.1;
}
.section-nav .step.selected {
    opacity:1;
}
.section-form .package {
    cursor:pointer;
}
.section-form .pointer {
    cursor:pointer;
}
.section-form input[type=number] {
    padding-left:5px;
    padding-right:5px;
    text-align:center;
    max-width:42.66px;
}
.section-form .was-validated input[type=number].form-control {
    max-width:60px;
}

.cursor-pointer {
    cursor:pointer;
}

.grayed-out {
    color:#797575;
}
.inactive-package {
    opacity:0.5;
}
.active-package {
    opacity:1;
}
.label-image {
    width:20px;
    vertical-align: -4px;
}
.total {
    font-size:32px;
}
.selected-addon {
    padding-left:15px;
}


//Datepicker
.datepicker.dropdown-menu {
    color:black;
}
.datepicker table tr td.disabled, .datepicker table tr td.disabled:hover {
    color:red;
    opacity:0.5;
}

.main-wrapper {
    opacity:1;
    transition:all 0.2s ease-in-out;
}

.main-wrapper.loading {
    pointer-events:none;
    opacity:0.2;
}
.btn-nav {
    opacity:1;
    transition:all 0.2s ease-in-out;
}

.btn-nav.loading {
    pointer-events:none;
    opacity:0.2;
}
#date::placeholder {
    color: #FFFFFF;
    opacity: 1;
}

#date:-ms-input-placeholder {
    color: #FFFFFF;
}

#date::-ms-input-placeholder {
    color: #FFFFFF;
}
.toggle-mobile {
    transition: all 0.2s ease-in-out;
}
.toggle-mobile.upside-down {
    transform:rotateZ(180deg);
}
.form-check-input {
    border:1px solid #FFFFFF;
}
.transfer-check {
    padding-left:0px;
}
.transfer-check .form-check-input {
    float:unset;
    margin:0;
    margin-right:44px;
    width:1.5em;
    height:1.5em;
}
.small-size {
    font-size:10px;
}

@keyframes flash {
    0% {
        background-color:unset;
    }
    50% {
        background-color:#464444;
    }
    100% {
        background-color:unset;
    }
}

@media(max-width:767px) {
    .mobile-only-toggler {
        background-color:unset;
    }
    .mobile-only-toggler.show {
        animation-name: flash;
        animation-duration: 0.2s;
    }
    .btn-nav {
        padding-left:calc(var(--bs-gutter-x) * 0.5);
        padding-right:calc(var(--bs-gutter-x) * 0.5);
    }
    .quant.fix-mobile {
        order: 1;
        width: 100%;
        margin:auto !important;
    }
    .minus.fix-mobile {
        order: 2;
        width: 50%;
    }
    .plus.fix-mobile {
        order: 3;
        width: 50%;
    }
    .btns {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        border-top: 4px solid;
        padding-top: 8px;
        padding-bottom:8px;
        background-color:#333333;
    }
    .section-nav .step {
        display:none;
    }
    .section-nav .step.selected {
        display:block;
    }
    h3 .small-size {
        display:block;
    }
}
